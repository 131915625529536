import React from 'react';
import './Art.css';
import { Link } from 'react-router-dom'; 

function ContactMe() {
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/dirt.png'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <h1
        style={{
          color: '#fff',
          textShadow: '2px 2px 4px #000',
          marginBottom: '20px',
          fontFamily: 'Minecraftia, sans-serif',
        }}
      >
       Contact Me
      </h1>
      <p style={{
          color: '#fff',
          textShadow: '2px 2px 4px #000',
          marginBottom: '20px',
          fontFamily: 'Minecraftia, sans-serif',
          textAlign: 'center'
        }}>Hello! I have removed my phone number and email from this page to prevent spam and people
        from automatically scraping my website- please see my resume page for contact details!
      </p>
      <footer className="art-footer">
              <Link to="/" className="menu-button done-button">Done</Link>
            </footer>
    </div>
    
  );
}

export default ContactMe;