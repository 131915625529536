import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Art.css';

function Art() {
  return (
    <div
      className="art-page"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/dirt.png'})`, // Correctly resolve dirt.png from the public folder
      }}
    >
      {/* Header with no overlay */}
      <header className="art-header">
        <h1>My Art</h1>
      </header>

      {/* Main content with transparent black overlay */}
      <div className="art-overlay">
        {/* Example Section with Title, Description, Image, and MP3 Player */}

        <div className="art-section">
  <h2>Content Creation</h2>
  <p style={{ textIndent: '30px' }}>
    Most recently, I've been making short-form video content for fun. The idea originally started with me wanting to
    document the progress of my Venus Flytraps. Since then, I've acquired a green screen and learned CapCut Pro. 
    I have a few videos on my other interests currently in the works.
  </p>
      {/* YouTube Embed */}
      <div className="youtube-embed">
  <p className="audio-description">
    Here are some videos I created showcasing my Venus Flytrap rescue journey! </p>
    <p className="audio-description">I've also including a more
    technical-style video giving a basic explanation of crypto.</p>
  <div style={{ height: '20px' }}></div>
  <div className="video-container">
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/nIk-LJrj2Dc"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/q5x9uxiTzwE"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/JbTkzTqk0gU"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
</div>
</div>
        <div className="art-section">
          <h2>Sound Art</h2>
          <p style={{ textIndent: '30px' }}>I love music and the idea of sound as a medium for art. In 2023-2024, 
            I learned how to use Adobe Auditon and made several narrative-style sound projects documenting my life.
             You can listen to a couple of them below, they are roughly in descending order of effort/importance to me.</p>

             <div style={{ height: '40px' }}></div>
          <h3>My Life (11:37)</h3>
          <p className="audio-description">
            This piece is the culmination of my foray into audio art. It's a narrative summary of my entire childhood and high school life - 
            essentially a sound self-portrait. This piece includes archival audio from all stages of my life. 
            Warning: some references to smoking + teenage debauchery.
        </p>
          <div className="art-audio-container">
            <iframe
              src="https://drive.google.com/file/d/1hPaU3Sxg54pLXTGL16cZwiWzh5Cnzok7/preview" // Replace with your Google Drive MP3 file ID
              width="100%"
              height="60"
              frameBorder="0"
              allow="autoplay"
              title="Google Drive MP3 Player"
            ></iframe>
          </div>
          <h3>A Week at Tufts (4:28)</h3>
          <p className="audio-description">
        This audio diary captures a week in my life as a student at Tufts University. Mainly covers the more mundane/non-academic aspects
        of college life. Conversations with friends, groceries, laundry, concerts, etc.
        </p>
          <div className="art-audio-container">
            <iframe
              src="https://drive.google.com/file/d/1fw701pbTnMRih5E0RYWlI09oAO3ghPei/preview" // Replace with your Google Drive MP3 file ID
              width="100%"
              height="60"
              frameBorder="0"
              allow="autoplay"
              title="Google Drive MP3 Player"
            ></iframe>
          </div>
          <h3>Making Breakfast (4:28)</h3>
          <p className="audio-description">
            A fast-cut sound compilation of cooking the breakfast I usually make. Can you guess what it is?
        </p>
          <div className="art-audio-container">
            <iframe
              src="https://drive.google.com/file/d/1zASoPqDCokRgfrEcEyj4HZyNdGt0RI8o/preview" // Replace with your Google Drive MP3 file ID
              width="100%"
              height="60"
              frameBorder="0"
              allow="autoplay"
              title="Google Drive MP3 Player"
            ></iframe>
          </div>
        </div>        
        <div className="art-section">
          <h2>Photography</h2>
          <p style={{ textIndent: '30px' }}>This part is self explanatory- I like taking pictures. Most recently, I took some
            macro shots on the Sony A6600 while camping. I've also taken several stacks of polaroids dating back to High school. You can see some of my favorites below.
          </p>
          <div style={{ height: '30px' }}></div>
          <div className="photo-grid-1"
          style={{
            textAlign: 'center', // Center horizontally
          }}>
            <iframe 
            src="https://drive.google.com/file/d/1PXl4keERlgyy4JbejyYaKWwKshIGO_vW/preview" 
            width="450" 
            height="300" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1jGC70y8CqBvW3jONV87KK1XXxRqO4xnr/preview" 
            width="450" 
            height="300" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/10fV5W-eKI3K9XRpgFl3fhiWHZGe4sD4Q/preview" 
            width="450" 
            height="300" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/10GQrxFDuWM82AFL0oLlZ70iPPCS-zKH_/preview" 
            width="450" 
            height="300" 
            allow="autoplay"
            title="p1"
          ></iframe>
          </div>
          <div style={{ height: '20px' }}></div>
          <div className="photo-grid-2"
          style={{
            textAlign: 'center', // Center horizontally
          }}>
          <iframe 
            src="https://drive.google.com/file/d/19HcQtfyykypvL7oIZLWgpm258siY2_1T/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1hnGFelATQlyU-dnhCDMUcsTsN0xZzF1u/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p2"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1TbHJH6vKIJSVKkoNreEr7eIkflUjy0J8/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p3"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1ldsEpv0Wb7OGGAdFDRF1Pk9ib38GbOD9/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p4"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1gElmqQrBSQLSMnNy-trtzMAgqKpaRtAx/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p5"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1HVRJCKc90rw049hJTfBMzJUTRiIPxaE-/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p6"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/19HcQtfyykypvL7oIZLWgpm258siY2_1T/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p7"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1-Hqp5b3kwuME9SLsQ43jXryl_gOIP_bQ/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p8"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1Qqt1yikTdr_L5L3DOi4Bf-MkOMydNSNe/preview" 
            width="300" 
            height="400" 
            allow="autoplay"
            title="p9"
          ></iframe>
          
          
          
          
          </div>
        </div>
      </div>

      {/* Footer with "Done" button */}
      <footer className="art-footer">
        <Link to="/" className="menu-button done-button">Done</Link>
      </footer>
    </div>
  );
}

export default Art;