import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Art.css'; // Reuse the styles from Art.css

function AboutMe() {
    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = `${process.env.PUBLIC_URL}/mossybrick-c.png`;
      }, []);
    
      return (
    <div
    className="art-page"
    style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/mossybrick-c.png'})`, // Correctly resolve gravel image from the public folder
        backgroundSize: '1100px 750px', // Adjust this for zoomed-out effect
    }}
    >
      {/* Header with no overlay */}
      <header className="art-header">
        <h1>About Me</h1>
      </header>

      {/* Main content with transparent black overlay */}
      <div className="art-overlay">
        <div className="art-section">
          <h2>Hello</h2>
          <p>I'm Will, I like tinkering with stuff and making art. check out my other pages 
            for more : )</p>
          <p>THIS PAGE IS UNDER CONSTRUCTION (3/12/25)</p>
        </div>
      </div>

      {/* Footer with "Done" button */}
      <footer className="art-footer">
        <Link to="/" className="menu-button done-button">Done</Link>
      </footer>
    </div>
  );
}

export default AboutMe;
