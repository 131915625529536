import React from 'react';
import { useEffect } from 'react';
import MenuButton from '../components/MenuButton';
import './Art.css'; // Reuse the styles from Art.css

function Projects() {
    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = `${process.env.PUBLIC_URL}/endstone1-c.jpg`;
      }, []);
    
      return (
    <div
    className="art-page"
    style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/endstone1-c.jpg'})`, // Correctly resolve gravel image from the public folder
        backgroundSize: '1500px 1000px', // Adjust this for zoomed-out effect
    }}
    >
      {/* Header with no overlay */}
      <header className="art-header">
        <h1>My Projects</h1>
      </header>

      {/* Main content with transparent black overlay */}
      <div className="art-overlay">
        <div className="art-section">
          <h2>The MIDI-Slide</h2>
          <p>This project was created as part of my EMID (Electronic music instrument design) course at Tufts,
            probably one of my favorite courses there. It's essentially an electronic pad comprised of 4 Soft-pot strips
            that act as strings. There is also a wireless hand-held controller that can be used to activate the notes, in addition
            to "plucking" the strings. There are three separate microcontrollers (ESP32, Arduino Mega, Arduino Nano) that communicate
            via Serial messages which get converted into MIDI in Max.
          </p>
          <div style={{ height: '20px' }}></div>
          <iframe width="100%" height="500" 
          src="https://www.youtube.com/embed/L39a3X5nuDc?si=cjW5v1CG6-MAEsFk" 
          title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; 
          encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <div className="photo-grid-1"
          style={{
            textAlign: 'center', 
          }}>
            <iframe 
            src="https://drive.google.com/file/d/1TW0c6HYVZFqSr-j3MGjqvZnNHGvpc1Es/preview" 
            width="400" 
            height="400" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1DdqXc6TQ1szeN3foXef0ce2EmY-CuJ29/preview" 
            width="400" 
            height="400" 
            allow="autoplay"
            title="p1"
          ></iframe>
          </div>
        </div>
        <div className="art-section">
          <h2>Project Soundscape</h2>
          <p>This is my Senior Capstone project at Tufts- an immersive sound room/experience that tracks
            your location and plays sound from anywhere around you. - Currently still in progress with more pictures
            and videos to come soon!
          </p>
          <div style={{ height: '20px' }}></div>
          <div className="photo-grid-2"
          style={{
            textAlign: 'center', 
          }}>
            <iframe 
            src="https://drive.google.com/file/d/1Z6zZv2pcREt58fDTgvHw_iUFhIqG191B/preview" 
            width="400" 
            height="400" 
            allow="autoplay"
            title="p1"
          ></iframe>
          <iframe 
            src="https://drive.google.com/file/d/1XZvSdnzqNe6dhWR6RR4rSgDDl77fYgn3/preview" 
            width="400" 
            height="400" 
            allow="autoplay"
            title="p1"
          ></iframe>
          </div>
        </div>
        <div className="art-section">
          <h2>Twitter Stock Bot</h2>
          <p>Simple python script to grab tweets and analyze them based on sentiment. Used Selenium
            and VADER Sentiment analysis dictionary. Ran into many issues, Twitter bot detection often
            throttled the speed at which I could collect tweets so the sample sizes were not big enough.
            Additionally, many tweets were often Bots reposting or advertising certain stocks, so those were
            overly positive. I discovered that in general, tweets about stocks tended to be positively biased.
          </p>
          <a href="https://github.com/wil-xia/selenium-helper/blob/main/seltest.py" target="_blank" rel="noopener noreferrer">GitHub</a>  
          






        </div>
        <p>THIS PAGE IS UNDER CONSTRUCTION (3/12/25)</p>
      </div>

      <footer className="art-footer">
        <MenuButton to="/" className="menu-button done-button">Done</MenuButton>
      </footer>
    </div>
  );
}

export default Projects;
